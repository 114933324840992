<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Report Trial Balace</h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tipe Tanggal</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <CSelect id="DateType" class="font-weight-bold" @change="onChangeDateType()" :options="DateTypeData" :value.sync="DateType" />
                                <label id="errorDateType" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Pengaturan</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="Setting" class="pb-3" :options="SettingData" v-model="Setting" @input="onChangeSetting()"/>
                                <label id="errorSetting" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>

                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Mulai</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <date-picker
                                    v-model="DateFrom"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    @change = "onChangeDateFrom"
                                ></date-picker>
                                <label id="errorDateFrom" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>

                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Akhir</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <date-picker
                                    v-model="DateTo"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    @change = "onChangeDateTo"
                                ></date-picker>
                                <label id="errorDateTo" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>  
                </CRow>
                
                <hr>
                <CRow >
                    <CCol class="sm-3">
                        <button id="view" class="btn btn-success" style="float:left"
                            @click="viewClick()">
                            <i class="fa fa-eye"></i> View
                        </button>
                    </CCol>
                </CRow>
                <hr>

                <CRow>
                    <CCol>
                        <datasource ref="settingDataSource" :data="this.SettingGridData" :page-size="10" :schema-model-fields="this.SettingSchemaModel"/>
                        <kendo-grid ref="gridItem"
                            :data-source-ref="'settingDataSource'"
                            :pageable="true"
                            :filterable="filterableConfig"
                            :sortable="true"
                            :editable="false"
                            :resizable="true"
                        >
                            <kendo-grid-column  :field="'head'"
                                                :title="'Judul'"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                            <kendo-grid-column  :field="'coa_group'"
                                                :title="'Grup COA'"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                            <kendo-grid-column  :field="'coa_id'"
                                                :title="'COA'"
                                                :editor= "COADropDownEditor"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                            <kendo-grid-column  :title="'&nbsp;'"
                                                :attributes="{ class: 'k-text-center' }"
                                                :command="['destroy']"
                                                :width= 200></kendo-grid-column>
            
                        </kendo-grid>
                    </CCol> 
                </CRow>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import trialBalanceReportServices from './Script/TrialBalanceReportService.js';
import { response } from '../../../infrastructure/constant/response';
import { report } from '../../../infrastructure/constant/variable';
import { kendo_grid } from '../../../infrastructure/constant/variable';

export default {
    name: 'TrialBalanceReport',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        // 'setting-balance-form' : settingBalanceForm,
    },
    async mounted () {
        this.DateTypeData = this.$globalfunc.getDateTypeData();
        this.DateType = report.default_date;

        this.onChangeDateType();

        var dateFrom = moment().startOf('month').format('YYYY-MM-DD');
        var dateTo = moment().endOf('month').format('YYYY-MM-DD');

        this.DateFrom= dateFrom;
        this.DateTo= dateTo;
        this.SettingData= await trialBalanceReportServices.getBalanceSettingQuery();
        this.Setting= '';
    },
    data(){
        return{
            DateFrom: '',
            DateTo: '',
            SettingData: [],
            Setting: '',
            DateTypeData: [],
            DateType: '',
            //grid
            SettingGridData: [],
            SettingSchemaModel: {
                head: { type: "string", editable: true, validation: {required: true} },
                coa_group: { type: "string", editable: true, validation: {required: true} },
                coa_id: { type: "string", editable: true, validation: {required: true} },
            },
            
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
        }
    },
    methods: {
        COADropDownEditor(container, options) {
            var vue = this;
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: false,
                    dataTextField: "label",
                    dataValueField: "value",
                    filter: "contains",
                    dataSource: {
                        data: vue.COAData
                    }
                });
        },
        async onChangeSetting(){
            if(this.Setting == null) {
                this.SettingGridData = [];
            }else{
                var data = await trialBalanceReportServices.getBalanceSettingData(this.Setting.value);
                this.SettingGridData = data;
            }
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.DateFrom == null || this.DateFrom == ''){
                this.errorShow('errorDateFrom');
                this.Error++;
            }
            if(this.DateTo == null || this.DateTo == ''){
                this.errorShow('errorDateTo');
                this.Error++;
            }
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
                this.Error++;
            }

            var gridData = this.$refs.gridItem.kendoWidget().dataSource._data;
            if(gridData.length <= 0){
                this.$swal("Error", "Pengaturan harus diisi terlebih dahulu", "error");
                this.Error++;
            }
        },
        async onChangeDateType(){
            var dateTypeData = this.$globalfunc.getDateTypeDetail(this.DateType);

            this.DateFrom = dateTypeData.startDate == null ? this.DateFrom : dateTypeData.startDate;
            this.DateTo = dateTypeData.endDate == null ? this.DateTo : dateTypeData.endDate;
            this.gridReload++;
        },
        async onChangeDateFrom(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateFrom = this.DateFromChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }
            this.gridReload++;
            this.DateType = 'Custom';
        },
        async onChangeDateTo(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateTo = this.DateToChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }
            this.gridReload++;
            this.DateType = 'Custom';
        },
        viewClick(){
            this.inputValidation();
            
            if(this.Error == 0){
                this.$router.push({ name: 'Trial Balance Report Grid', params: { datefrom: this.DateFrom, dateto: this.DateTo, settingid: this.Setting.value } });
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>

<style scoped>
</style>